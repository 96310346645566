<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showPdf" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <ticket-pdf-viewer :url="url" v-if="url" @print="handleTicketPrinted()"></ticket-pdf-viewer>
    </el-drawer>

    <el-drawer title="" :visible.sync="showAssignSeat" direction="btt" :before-close="getTickets" size="85%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-11">
            <group-ticket-assign-seat :group_ticket="group_ticket" v-if="group_ticket" :all="assignAll"
              @close="getTickets"></group-ticket-assign-seat>
          </div>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showReschedulesHistory" direction="btt" :before-close="handleClose" size="50%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <reschedules-history :ticket="selected" v-if="selected"></reschedules-history>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showResched" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <reschedule-ticket-form :ticket="selected" v-if="selected" @save="getTickets()"></reschedule-ticket-form>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showUpgrade" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <upgrade-ticket-form :ticket="selected" v-if="selected" @save="getTickets()"></upgrade-ticket-form>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showRefund" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <refund-ticket-form :ticket="selected" v-if="selected" @save="getTickets()"></refund-ticket-form>
        </div>
      </div>
    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header" v-if="group_ticket">
        <div class="category">Transaction Code <span style="font-weight: bold;">{{ group_ticket.code }}</span></div>
        <div>Created on: {{ group_ticket.created_at }}</div>
      </div>
      <div class="card-body row">
        <div class="col-lg-3" v-if="group_ticket && group_ticket.status < 2" >
          <confirm-delete klass="btn-block" @onClick="handleDeleteAll()" size="medium"
            :message="' Are you sure you want to remove all tickets?  '" label="Remove all tickets"></confirm-delete>
        </div>
       
        <div class="col-lg-3">
          <el-select class="btn-block" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        
        <div class="col-lg-2"></div>
        <div class="col-lg-4">
          <div class="">
            <fg-input class="input-sm btn-block" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">

            <el-table-column :min-width="150" class-name="" label="Passenger Name">
              <template slot-scope="props">
                <div class="semi-bold">
                  {{ props.row.ticket_detail.passenger.full_name }}<br>
                  <div>{{ props.row.ticket_detail.code }}</div>
                <div v-if="props.row.created_by && props.row.status != 1" class="note">ISSUED BY: {{
                  props.row.created_by }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column :min-width="100" class-name=" bold" label="Ticket">
              <template slot-scope="props">
                <div class="semi-bold">
                  {{ props.row.accommodation.code }} - {{ props.row.type == 1 ? 'OW' : 'RT' }}
                <span v-if="props.row.online_booking_id">(ONLINE)</span> <br>
                {{ $store.state.passenger_types[props.row.passenger_type] }} <br>
                {{ props.row.booking.route.code }}
                </div>
              </template>
            </el-table-column>


            <el-table-column :min-width="100" class-name=" bold" label="Date/Time">
              <template slot-scope="props">
                <div class="semi-bold">
                  <span v-if="!props.row.is_open">
                  {{ props.row.booking.booking_date }}<br>
                  {{ props.row.booking.formatted_time }}</span>
                <span v-if="props.row.is_open">(OPEN)</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :min-width="130" class-name=" text-right bold font-size-15" label="Total Fare">
              <template slot-scope="props">
                <div style="text-align: right;" class="semi-bold">
                  <div>
                    {{ money(props.row.ticket_detail.fare) }} FARE
                    <div v-if="props.row.ticket_detail.fca"> + {{ money(props.row.ticket_detail.fca) }} FCA</div>
                  </div>
                  <div>
                    = {{ money(props.row.ticket_detail.total_fare) }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column :min-width="100" class-name="" label="STATUS">
              <template slot-scope="props">
                <div v-if="props.row.is_refunded">REFUNDED</div>
                <div v-else>
                  {{ $store.state.tickets_status[props.row.status] }}
                </div>
                <div v-if="props.row.has_been_rescheduled">
                  (RESCHEDULED) <el-link type="warning"
                    @click="handleShowReschedulesHistory(props.row)">(SHOW)</el-link>
                </div>

                <div v-if="props.row.has_been_upgraded">UPGRADED</div>

                <div v-if="props.row.reject_status == 1">
                  (REJECT REQUESTED)
                </div>
                <div v-if="props.row.reject_status == 2">
                  (REJECTED)
                </div>

                <div v-if="props.row.is_expired">EXPIRED</div>
                <div v-if="props.row.is_non_refundable">NON-REFUNDABLE</div>
                <div>
                  <span class="text-primary">SEAT NO: <br><span style="font-size: 20px;font-weight: bolder">{{
                      props.row.ticket_seat ?
                      props.row.ticket_seat.seat_number :
                      'N/A' }}</span>
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column :min-width="150" class-name="td-actions bold" label="Actions">
              <template slot-scope="props">
                <div v-if="props.row.status >= 2">

                  <el-tooltip class="item" effect="dark" content="Reject Ticket" placement="top"
                    v-if="props.row.can_reject && user_id == props.row.created_by_id">
                    <confirm-button title="You're requesting to reject this ticket. Ready to proceed?" label=""
                      :size="'small'" :type="'danger'" :icon="'fa fa-ban'" class="mr-2"
                      @onClick="handleRequestReject(props.row)">
                    </confirm-button>

                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Print Ticket" placement="top"
                    v-if="props.row.can_print">
                    <el-button type="default" link @click="handleDownloadTicket(props.row)" size="mini">
                      <i class="fa fa-download"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Free Refund" placement="top"
                    v-if="props.row.can_refund">
                    <el-button type="warning" link @click="handleFreeRefund(props.row)" size="mini">
                      <i class="fa fa-minus-square"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark"
                    :content="'Refund ' + $store.state.refund_types[props.row.refund_type_option] + ' ' + props.row.refund_rate +' %'"
                    placement="top" v-if="props.row.can_refund">
                    <el-button type="info" link @click="handleShowRefund(props.row)" size="mini"
                      v-if="props.row.refund_type_option != null">
                      <i class="fa fa-minus-square"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark"
                    :content="'Rebooking ' + $store.state.resched_types[props.row.resched_type_option] + ' ' + props.row.rebooking_rate+' %'"
                    placement="top" v-if="props.row.can_reschedule">
                    <el-button type="primary" link @click="handleResched(props.row)" size="mini"
                      v-if="props.row.resched_type_option != null">
                      <i class="fa fa-mail-forward"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" :content="'Upgrade'" placement="top"
                    v-if="props.row.is_upgradable">
                    <el-button type="warning" link @click="handleUpgrade(props.row)" size="mini">
                      <i class="fa fa-upload"></i>
                    </el-button>
                  </el-tooltip>
                </div>

                <div v-if="props.row.status == 1 && !props.row.online_booking_id">
                  <!-- <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                    <i class="fa fa-edit"></i>
                  </el-button> -->
                  <confirm-delete @onClick="handleDelete(props.row)"
                    :message="props.row.type == 2 ? 'Deleting this ticket will also delete its corresponding partner ticket. Are you sure you want to continue?  ': null"></confirm-delete>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
        <div class="col-md-12" v-if="group_ticket">
          <hr>
          <div>Total Fare: <span style="font-size: 50px;font-weight: bold" class="text-dark">{{
              money(group_ticket.total_fare)
              }}</span></div>

          <div style="float: right;" v-if="tickets && tickets.length">
            <el-button v-if="group_ticket && group_ticket.status < 2 " type="warning" size="small"
              @click="handleAssignSeat('all')" style="margin-right: 10px;">Auto Assign Seat To All</el-button>
            <el-button v-if="group_ticket" type="primary" size="small"
              @click="handleAssignSeat()" style="margin-right: 10px;">Assign Seat</el-button>

            <el-button v-if="group_ticket && group_ticket.status < 2" type="primary" size="small"
              @click="handleSubmit()" style="margin-right: 10px;" ref="finalSave">Final Save and Print</el-button>


            <el-alert :closable="false" type="warning" v-if="group_ticket && group_ticket.status < 2"
              style="margin-top:10px">You can press Ctrl + Enter to save and
              print the tickets.</el-alert>

            <el-button v-if="group_ticket && group_ticket.status == 2" type="primary" size="small"
              @click="handlePrintAll()">Print All</el-button>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GroupTicket
} from '@/resources/group_ticket'

import {
  Ticket
} from '@/resources/ticket'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import ConfirmButton from '../../Common/ConfirmButton.vue'
import TicketPdfViewer from './TicketPdfViewer.vue'
import Swal from 'sweetalert2'
import RescheduleTicketForm from './RescheduleTicketForm.vue'
import UpgradeTicketForm from './UpgradeTicketForm.vue'
import ReschedulesHistory from './ReschedulesHistory.vue'
import GroupTicketAssignSeat from './GroupTicketAssignSeat.vue'
import RefundTicketForm from './RefundTicketForm.vue'

export default {
  components: {
    ConfirmDelete,
    ConfirmButton,
    TicketPdfViewer,
    RescheduleTicketForm,
    UpgradeTicketForm,
    ReschedulesHistory,
    GroupTicketAssignSeat,
    RefundTicketForm
  },
  props: ['update', 'g_ticket'],
  data() {
    return {
      showAssignSeat: false,
      showReschedulesHistory: false,
      showUpgrade: false,
      showResched: false,
      showDelete: false,
      showView: false,
      showForm: false,
      showPdf: false,
      tickets: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      group_ticket: null,
      term: null,
      url: null,
      user_id: null,
      assignAll: false,
      showRefund: false
    }
  },
  mounted() {
    // Add an event listener for keypress when the component is mounted
    window.addEventListener('keyup', this.handleShortcut);
  },
  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('keyup', this.handleShortcut);
  },
  watch: {
    g_ticket() {
      this.group_ticket = Object.assign({}, this.g_ticket)
      this.getTickets()
    },
    update() {
      this.getTickets()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    if (this.g_ticket) this.group_ticket = Object.assign({}, this.g_ticket)
    this.getTickets()
  },
  methods: {
    handleShortcut(event) {
      // Check if "Ctrl" + "Enter" is pressed
      if (event.ctrlKey && event.key.toLowerCase() === 'enter' && this.$refs.finalSave) {
        console.log(event.key)

        this.$refs.finalSave.$el.click(); // Trigger button click
      }
    },
    handleAssignSeat(option) {
      this.showAssignSeat = true
      this.assignAll = option == 'all'
    },
    handleShowReschedulesHistory(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showReschedulesHistory = true
    },
    handleDeleteAll() {
      GroupTicket.delete(this.group_ticket)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'All tickets have been removed',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleTicketPrinted() {
      this.showPdf = false
      this.$emit('save', true)
      this.getTickets()
    },
    handleDownload() {

    },
    handleRequestReject(ticket) {
      this.$loading = true
      this.$loadingText = "Requesting to reject"

      Ticket.requestReject(ticket.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Reject request has been submitted',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadTicket(ticket) {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.downloadTicket(ticket.id)
        .then(result => {
          this.url = result.data.url
          this.showPdf = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handlePrintAll() {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.downloadTickets({
          group_ticket_code: this.group_ticket.code
        })
        .then(result => {
          this.url = result.data.url
          this.showPdf = true
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleSubmit() {
      this.$loading = true
      this.$loadingText = "Preparing to generate ticket"

      Ticket.saveAndPrint(this.group_ticket.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Transaction has been confirmed',
          });

          this.url = result.data.url
          this.showPdf = true
          this.$emit('saveAndPrint', true)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleView(model) {
      this.showView = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showView = false
      this.showPdf = false
      this.showResched = false
      this.showUpgrade = false
      this.showReschedulesHistory = false
      this.showAssignSeat = false
      this.assignAll = false
      this.showRefund = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      Ticket.delete({
          id: model.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket has been removed',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTickets() {
      this.handleClose()

      if (!this.g_ticket) {
        this.getPendingTickets()
        return
      }

      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        group_ticket_code: this.group_ticket?.code
      }
      this.$loading = true
      Ticket.get(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
          this.group_ticket = result.data.group_ticket
          this.user_id = result.data.user_id
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPendingTickets() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      Ticket.pendingTickets(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
          this.group_ticket = result.data.group_ticket
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleUpgrade(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showUpgrade = true
    },
    handleResched(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showResched = true
    },
    handleShowRefund(ticket) {
      this.selected = Object.assign({}, ticket)
      this.showRefund = true
    },

    async handleFreeRefund(ticket) {
      const defaultSeriesNumber = ticket.online_booking_id ? ticket.ticket_detail.code : null; // Set your default series number

      let amount = ticket.ticket_detail.fare + ticket.ticket_detail.fca;
      let message = "(Free)"
      let series_number = null
      const {
        value: res
      } = await Swal.fire({
        title: `${message} Refund`,
        text: `The ticket will be refunded for ${this.money(amount)}`,
        icon: "warning",
        input: "text", // Add input field
        inputPlaceholder: "Enter the series number",
        inputValue: defaultSeriesNumber,
        footer: '<span style="font-size: 0.9em; color: gray;">Put the ticket code for online tickets</span>', // Add note
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-info",
        buttonsStyling: false,
        preConfirm: (value) => {
          if (!value) {
            Swal.showValidationMessage("Please enter a series number!");
          }
          series_number = value
          return value; // Return the series number
        }
      });

      if (!res) return;

      this.$loading = true
      Ticket.refund(ticket.id, {free: true, series_number: series_number  })
        .then(result => {
          this.successRefund(ticket)
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    async handleRefund(ticket) {
      let message = ticket.refund_type_option != null ? this.$store.state.refund_types[ticket.refund_type_option]+' '+ticket.refund_rate+ ' %' : ''
      if (ticket.refund_rate == 0) message = "(Free)"
      let return_amount = ticket.expected_refund_return_amount;

      const {
        value: res
      } = await Swal.fire({
        title: message + "  Refund ",
        html: "<div>A fee of " + this.money(ticket.expected_refund_amount) + " will be applied to the ticket</div>" +
          "<div>You can refund " + this.money(return_amount) + " to the passenger</div>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-info",
        buttonsStyling: false
      })

      if (!res) return

      this.$loading = true
      Ticket.refund(ticket.id)
        .then(result => {
          // this.successRefund(ticket)
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket has been refunded',
          });
          this.getTickets()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    successRefund(ticket) {
      Swal.fire({
        title: "Success",
        text: "The ticket of passenger " + ticket.ticket_detail.passenger.full_name + ' has been refunded',
        icon: "success",
        confirmButtonText: "Close",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })
    }
  }
}
</script>

<style lang="scss"></style>
