<template>
  <div v-if="vessel">
    <el-tabs type="border-card" v-model="active_tab">
      <el-tab-pane :label="accommodation.name" v-for="accommodation in accommodations" :key="accommodation.id"
        :name="accommodation.code" style="overflow: auto;">
        <vessel-seat-plan-form :accommodation="accommodation" :vessel_detail="vessel"
          v-if="active_tab == accommodation.code" :booking="booking" :allow_block="allow_block" @reserveSeat="handleReserveSeat($event)"></vessel-seat-plan-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Vessel } from '@/resources/vessel'
import { Accommodation } from '@/resources/accommodation'
import VesselSeatPlanForm from './VesselSeatPlanForm.vue';

extend("required", required);

export default {
  components: { VesselSeatPlanForm },
  props: ['vessel', 'booking', 'allow_block'],
  data() {
    return {
      active_tab: null,
      form: null,
      accommodations: [],
      loading: false
    };
  },
  watch: {
    vessel: function () {
      if (this.vessel) this.form = Object.assign({}, this.vessel)
    }
  },
  created: function () {
    if (this.vessel) this.form = Object.assign({}, this.vessel)
    this.getAccommodations()
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {
    getAccommodations() {
      this.$loading = true
      Accommodation.get({ params: {} })
        .then(result => {
          if (result.data.accommodations) {
            this.accommodations = result.data.accommodations
          }
          this.filterAccommodations()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },  
    filterAccommodations(){
      let accommodations = []
      let booking_accommodations = this.booking ? this.booking.data.accommodations : []
      
      this.accommodations.forEach(function(accommodation){
        let found = false
        booking_accommodations.forEach(function(booking_accom){
          if (accommodation.id == booking_accom.id) found = true
        })
        if (found) accommodations.push(accommodation)
      })

      if (this.booking) this.accommodations = accommodations
      if (this.accommodations.length) this.active_tab = this.accommodations[0].code

    }
  }
}
</script>
<style></style>
