<template>
  <div>
    <div class="row" v-if="user && user.branch">
      <div class="col-md-12">
        <h4 class="text-primary bold">GOOD DAY <strong>{{ user.name }}</strong>!</h4>
      </div>
      <div class="col-md-12">
        <user-recent-transactions-list :update="update"></user-recent-transactions-list>
      </div>
      <div class="col-md-5">
        <ticket-form @save="handleGetTickets()" :update="resetForm"></ticket-form>
      </div>
      <div class="col-md-7">
        <tickets-list :update="update" @save="handleGetTickets()" @saveAndPrint="handleResetForm"></tickets-list>
      </div>
    </div>
    <div v-else>
      <h5>You haven't been assigned to a branch. Kindly request the administrator to assign you to a branch.</h5>
    </div>
  </div>
</template>

<script>
import TicketForm from './TicketForm.vue'
import TicketsList from './TicketsList.vue'
import UserRecentTransactionsList from './UserRecentTransactionsList.vue'
import {
  mapState
} from 'vuex'
export default {
  components: {
    TicketForm,
    TicketsList,
    UserRecentTransactionsList
  },
  data() {
    return {
      update: 0,
      resetForm: 0
    }
  },
  methods: {
    handleGetTickets() {
      this.update++ //this will trigger for the tickets to be fetched from the server
    },
    handleResetForm() {
      this.resetForm++
    }
  },
  computed: {
    ...mapState(['user'])
  },
}
</script>

<style lang="scss"></style>
