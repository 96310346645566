<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <no-trip-date-form @save="getDates()" v-if="showForm" :no_trip_date="selected"></no-trip-date-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Dates (No Trips)</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-select v-model="year">
                <el-option v-for="year in years " :key="year" :value="year">{{ year }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="dates" style="width: 100%">

            <el-table-column :min-width="120"  class-name="td-actions" label="Date">
              <template slot-scope="props">
                {{ props.row.date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120"  class-name="td-actions" label="Description">
              <template slot-scope="props">
                {{ props.row.description }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120"  class-name="td-actions" label="Routes">
              <template slot-scope="props">
                {{ props.row.routes }}
              </template>
            </el-table-column>


            <el-table-column :min-width="120"  class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
                <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NoTripDate
} from '@/resources/no_trip_date'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import NoTripDateForm from './NoTripDateForm.vue'

export default {
  components: {
    ConfirmDelete,
    NoTripDateForm,
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      dates: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
      years: [],
      year: null
    }
  },
  watch: {
    year: function () {
      this.getDates()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getDates()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getDates()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getDates()
    }
  },
  created: function () {
    let nextYear = parseInt(this.$moment().format('YYYY')) + 1
    this.years = [nextYear, nextYear - 1, nextYear - 2, nextYear - 3, nextYear - 4]
    this.year = nextYear-1
    this.getDates()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      NoTripDate.delete({
        id: model.id
      })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Date has been removed',
          });
          this.getDates()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getDates() {
      this.handleClose()
      let params = {
        year: this.year
      }
      this.$loading = true
      NoTripDate.get({
        params: params
      })
        .then(result => {
          if (result.data.dates)
            this.dates = result.data.dates
        })
        .catch(() => {
       
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
