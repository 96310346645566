<template>
    <div>
        <el-alert effect="dark">{{ note }}</el-alert>
        <br>
        <el-transfer filterable filter-placeholder="Search passengers" class="transfer-box"
            :titles="['Passengers', 'Selected']" v-model="selected_tickets" :data="list">
        </el-transfer>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} tickets</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
    </div>
</template>

<script>
import {
    Booking
} from '@/resources/booking'
import {
    Ticket
} from '@/resources/ticket'

export default {
    props: ['booking', 'update'],
    data() {
        return {
            list: [],
            tickets: [],
            selected_tickets: [],
            limit: 20,
            note: 'You can either transfer all or only '+this.limit+' passengers at a time',
            pagination: {
                perPage: 10,
                currentPage: 1,
                total: 0
            },
        }
    },
    watch: {
        update(){
            this.selected_tickets = []
            this.getTickets()
        },
        selected_tickets: function(){
            if (this.selected_tickets.length > this.limit) {
                this.$notify({
                    type: 'error',
                    title: "Error",
                    text: this.note,
                });
                this.setToMaxPassengers()
            }
            this.$emit('update', this.selected_tickets)
        }
    },
    async mounted() {
        this.getTickets()
    },
    methods: {
        setToMaxPassengers(){
            this.collectTickets(this.tickets)
            this.selected_tickets = this.selected_tickets.slice(0, this.limit);
        },
        async getTickets(query) {
            this.tickets = []
            let params = {
                term: query,
                page: 1,
                per_page: 500,
                booking_id: this.booking.id,
                simple: true
            }

            try {
                // Make an API request to fetch filtered data
                const response = await Ticket.fromBooking(params);
                this.pagination = response.data.meta
                 this.collectTickets(response.data.tickets)

            } catch (error) {
                console.error('Error fetching filtered data:', error);
            }
        },
        collectTickets(tickets) {
            let self = this
            this.list = []

            tickets.forEach(ticket => {
                self.list.push({
                    key: ticket.id,
                    label: ticket.ticket_detail.passenger.full_name,
                    disabled: false
                })
            });

            this.tickets = tickets
        }
    }
};

</script>


<style scoped></style>