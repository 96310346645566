<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <trip-schedule-form @save="getTripSchedules()" v-if="showForm" :trip_schedule="selected"></trip-schedule-form>
        </div>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showView" direction="btt" :before-close="handleClose" size="90%"
        :destroy-on-close="true">
        <div v-if="selected">
          <trip-schedule-view-trips :trip_schedule="selected"></trip-schedule-view-trips>
        </div>

      </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Trip Schedules</div>
      </div>
      <div class="card-body row">
       
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
         <div class="col-md-12">
            <el-alert>The end date will automatically adjust based on the specified number of days from the current date.</el-alert>
          </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="trip_schedules" style="width: 100%">

            <el-table-column :min-width="120" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Start Date">
              <template slot-scope="props">
                {{ props.row.start_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="End Date">
              <template slot-scope="props">
                {{ props.row.end_date }}
              </template>
            </el-table-column>
            
            <el-table-column :min-width="120" class-name="td-actions" label="Days to adjust from current date">
                <template slot-scope="props">
                  {{ props.row.days_to_auto_adjust_end_date }}
                </template>
              </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" link @click="handleView(props.row)" size="mini">
                    View Trips<i class="fa fa-eye"></i>
                  </el-button>

                <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
                <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { TripSchedule } from '@/resources/trip_schedule'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import TripScheduleForm from './TripScheduleForm.vue'
import TripScheduleViewTrips from './TripScheduleViewTrips.vue'

export default {
  components: { ConfirmDelete, TripScheduleForm, TripScheduleViewTrips },
  data() {
    return {
      showDelete: false,
      showView: false,
      showForm: false,
      trip_schedules: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTripSchedules()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTripSchedules()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTripSchedules()
    }
  },
  created: function () {
    this.getTripSchedules()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleView(model) {
      this.showView = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showView = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      TripSchedule.delete({ id: model.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Schedule has been removed',
          });
          this.getTripSchedules()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTripSchedules() {
      this.handleClose()
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      TripSchedule.get({ params: params })
        .then(result => {
          if (result.data.trip_schedules)
            this.trip_schedules = result.data.trip_schedules
        })
        .catch(() => {
        

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
