var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Reschedule Ticket ")]),(_vm.ticket)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"bold-text-blue"},[_vm._v(_vm._s(_vm.ticket.ticket_detail.passenger.full_name))]),(_vm.ticket.ticket_seat)?_c('div',[_vm._v("SEAT NUMBER: "+_vm._s(_vm.ticket.ticket_seat.seat_number))]):_vm._e(),_c('hr')]):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[(_vm.ticket)?_c('div',{staticClass:"col-md-12"},[_c('div',[_vm._v("Current Booking Schedule: "),_c('br'),_vm._v(_vm._s(_vm.ticket.booking.booking_date)+" - "+_vm._s(_vm.ticket.booking.formatted_time)+" ( "+_vm._s(_vm.ticket.booking.route.code)+") "),_c('span',[_vm._v(_vm._s(_vm.ticket.booking.status_label))])])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('reschedules-history',{attrs:{"ticket":_vm.ticket}})],1),_c('div',{staticClass:"col-md-12"},[_c('hr'),_c('div',[_vm._v("Select New Schedule")])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Booking Date")]),_c('ValidationProvider',{attrs:{"name":"booking_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
                    { 'has-danger': failed },
                    { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.booking_date),callback:function ($$v) {_vm.$set(_vm.form, "booking_date", $$v)},expression:"form.booking_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Booking Date is required")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Route")]),_c('ValidationProvider',{attrs:{"name":"route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
                    var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
  { 'has-danger': failed },
  { 'has-success': passed }],attrs:{"disabled":"","placeholder":"Select","filterable":""},on:{"change":function($event){return _vm.searchTime()}},model:{value:(_vm.form.route_code),callback:function ($$v) {_vm.$set(_vm.form, "route_code", $$v)},expression:"form.route_code"}},_vm._l((_vm.routes),function(route){return _c('el-option',{key:route.code,attrs:{"value":route.code,"label":route.code}},[_vm._v(_vm._s(route.code))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Route is required")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Time")]),_c('ValidationProvider',{attrs:{"name":"route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var passed = ref.passed;
  var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                  { 'has-danger': failed },
                  { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}},_vm._l((_vm.trips),function(trip){return _c('el-option',{key:trip.time,attrs:{"value":trip.time,"label":trip.formatted_time}},[_vm._v(_vm._s(trip.formatted_time))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Time is required")]):_vm._e()]}}],null,true)})],1)]),(_vm.booking)?_c('div',{staticClass:"col-md-12"},[_c('el-descriptions',{staticClass:"margin-top",attrs:{"title":"Summary","column":3,"size":"mini","border":""}},[_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" Booking Info ")]),_vm._v(" "+_vm._s(_vm.booking.vessel.code)+" ")],2),(_vm.booking)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" Capacity ")]),_vm._v(" "+_vm._s(_vm.capacity)+" ")],2):_vm._e(),(_vm.booking)?_c('el-descriptions-item',[_c('template',{slot:"label"},[_vm._v(" Available ")]),_vm._v(" "+_vm._s(_vm.capacity - _vm.total_passengers)+" ")],2):_vm._e()],1)],1):_vm._e()])])]),_c('div',{staticClass:" text-right"},[_c('el-button',{attrs:{"type":"primary","native-type":"submit","plain":"","disabled":!_vm.form.booking_id}},[_vm._v("SAVE "),(_vm.seat_number)?_c('span',[_vm._v(" with seat number: "+_vm._s(_vm.seat_number))]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("| "+_vm._s(_vm.money(_vm.ticket.expected_resched_amount))+" CHARGE")])]),_c('hr'),(_vm.booking && _vm.ticket.booking.id != _vm.booking.id)?_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"text-primary"},[_vm._v("Note: You may choose a seat number during revalidation.")]),_c('ticket-assign-seat',{attrs:{"ticket":_vm.ticket,"booking":_vm.booking,"checkOnly":true,"center":true},on:{"available":function($event){return _vm.setSeatNumber($event)}}})],1):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }