<template>
  <div class="mb-3">
    <el-drawer title="" :visible.sync="showTickets" direction="btt" :before-close="handleClose" size="80%"
      :destroy-on-close="true" :append-to-body="true">
      <div>
        <tickets-list :g_ticket="selected" v-if="selected"></tickets-list>
      </div>
    </el-drawer>
    <div class="row">
      <div class="col-md-3">

      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3">
            <el-date-picker size="small" placeholder="search by txn date"
              v-model="form.transaction_date"></el-date-picker>
          </div>
          <div class="col-md-3">
            <form @submit.prevent="submit">
              <el-input placeholder="Search by code" v-model="code" class="input-with-select" size="small">
                <el-button slot="append" icon="el-icon-search" size="small" type="button" @click="submit"></el-button>
              </el-input>
            </form>
          </div>

          <div class="col-md-12">
            <el-table :data="group_tickets" header-row-class-name="text-primary">
              <el-table-column :min-width="120" class-name="td-actions" label="Code">
                <template slot-scope="props">
                  {{ props.row.code }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" class-name="td-actions" label="Tickets">
                <template slot-scope="props">
                  {{ props.row.tickets_count }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" class-name="td-actions" label="Total">
                <template slot-scope="props">
                  {{ money(props.row.total_fare) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Txn Date">
                <template slot-scope="props">
                  {{ props.row.updated_at }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" class-name="td-actions" label="Action">
                <template slot-scope="props">
                  <el-button @click="handleShowTickets(props.row)" size="small" type="primary" plain>VIEW
                    TICKETS</el-button>
                </template>
              </el-table-column>

            </el-table>
          </div>

          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} records</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  GroupTicket
} from '@/resources/group_ticket'
import TicketsList from './TicketsList.vue'

export default {
  components: {
    TicketsList
  },
  props: ['update'],
  data() {
    return {
      selected: null,
      group_tickets: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      showTickets: false,
      code: null,
      form: {
        transaction_date: null
      }
    }
  },
  watch: {
    'pagination.currentPage': function () {
      this.getTransactions()
    },
    'form.transaction_date': function () {
      this.getTransactions()
    },
    update() {
      this.getTransactions()
    }
  },
  created: function () {
    this.getTransactions()
  },
  methods: {
    showAll() {

    },
    handleShowTickets(model) {
      this.showTickets = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showTickets = false
    },
    submit() {
      if (!this.code) return
      this.$loading = true
      GroupTicket.show(this.code)
        .then(result => {
          if (result) this.handleShowTickets(result.data.group_ticket)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTransactions() {
      let params = {
        status: this.status,
        page: this.pagination.currentPage,
        per_page: 10,
        status: 2,
        transaction_date: this.form.transaction_date ? this.$moment(this.form.transaction_date).format('YYYY-MM-DD') : null
      }
      this.$loading = true
      GroupTicket.userTransactions(params)
        .then(result => {
          if (result.data.group_tickets)
            this.group_tickets = result.data.group_tickets
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
