<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="  " v-if="current_booking" style="padding: 20px">
        <div class="">
          <h4 class="card-title">
            Transfer {{ current_booking.total_passengers }} Passengers
          </h4>
        </div>
        <div class="">
          <div class="row">
            <div class="col-md-12" v-if="booking">
              <div>Current Booking Schedule: <br>{{ booking.booking_date }} - {{ booking.formatted_time }}
                (
                {{ booking.route.code }}) <span>{{ booking.status_label }}</span></div>
            </div>

            <div class="col-md-8">
              <booking-transfer-list :booking="booking" @update="selected_tickets = $event"
                :update="update_transfer"></booking-transfer-list>
            </div>
            <div class="col-md-4">
              <br><br>
              <div>Select New Schedule</div>
              <hr>
              <div class="form-group">
                <label>Booking Date</label>
                <ValidationProvider name="booking_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.booking_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
                      { 'has-danger': failed },
                      { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Booking Date is required</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Time</label>
                <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.time" placeholder="Select" filterable class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                    <el-option :value="trip.time" :label="trip.formatted_time" v-for="trip in trips" :key="trip.time">{{
                      trip.formatted_time
                    }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Time is required</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>

        <div class=" text-right">
          <el-button type="primary" native-type="submit" plain :disabled="!form.booking_id">TRANSFER ALL</el-button>
          <el-button type="warning" native-type="button" plain :disabled="!form.booking_id || !selected_tickets.length"
            @click="handleTransferSelectedPassengers">TRANSFER {{ selected_tickets.length }} PASSENGERS ONLY</el-button>
          <hr>
        </div>
      </div>

    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

import {
  Booking
} from '@/resources/booking'
import moment from 'moment';

import Swal from 'sweetalert2'
import BookingTransferList from './BookingTransferList.vue';

extend("required", required);

export default {
  components: { BookingTransferList },
  props: ['booking'],
  data() {
    return {
      form: {
        booking_date: new Date(),
        time: null,
        booking_id: null
      },
      trips: [],
      selected_booking: null,
      selected_tickets: [],
      update_transfer: 0,
      current_booking: null
    };
  },
  watch: {
    booking: function () {
      if (this.booking) {
        this.form = {
          booking_date: this.booking.booking_date,
          time: null,
        }
        this.getTrips()
      }
    },
    'form.booking_date': function () {
      this.getTrips()
    },
    'form.time': function () {
      if (this.form.time) this.getBooking()
    }
  },
  created: function () {
    if (this.booking) {
      this.current_booking = Object.assign({}, this.booking)
      this.form = {
        booking_date: this.booking.booking_date,
        route_code: this.booking.route.code,
        time: null
      }
    }
  },
  methods: {
    searchTime() {
      this.getTrips()
    },
    clearData() {
      this.form.booking_id = null
    },
    handleTransferSelectedPassengers() {
      this.submit(true)
    },
    async submit(specific_passengers) {
      let message = "Attention"
      let remarks = "";
      if (!specific_passengers) {
        remarks = "Note that transferring all passengers esp if more than 20 will take some time"
      }
      const { value: res } = await Swal.fire({
        title: message,
        text: "This action cannot be undone. "+remarks+". Proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-info",
        buttonsStyling: false
      })

      if (!res) return

      this.$loading = true
      if (specific_passengers) {
        this.form.selected_tickets = this.selected_tickets
      } else {
        this.form.selected_tickets = null
      }

      Booking.transferPassengers(this.booking.id, this.form)
        .then(result => {
          this.current_booking = result.data.booking
          if (!specific_passengers) {
            this.$notify({
              type: 'success',
              title: "Success",
              text: 'All passengers have been transferred',
            });
            this.$emit('save', true)
          }
          else {
            this.$notify({
              type: 'success',
              title: "Success",
              text: this.selected_tickets.length+' passengers have been transferred',
            });
            this.update_transfer += 1
          }
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getBooking() {
      if (!this.form.booking_date) return
      if (!this.form.time) return

      this.$loading = true
      Booking.getBooking({
        for_transfer: true,
        date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
        route_code: this.booking.route.code,
        time: this.form.time
      })
        .then(result => {
          this.selected_booking = result.data.booking
          this.form.booking_id = result.data.booking.id
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },

    getTrips() {
      this.trips = []
      if (!this.form.booking_date) return
      this.$loading = true
      this.form.time = null
      Booking.getTrips({
        date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
        route_code: this.booking.route.code,
        per_page: 999,
        time_only: true
      })
        .then(result => {
          if (result.data.trips)
            this.trips = result.data.trips
          if (this.trips.length) this.form.time = this.trips[0].time
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style></style>
