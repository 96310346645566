import api from './api'

const API = '/api/client/reservations'

export const Reservation = {
    get: function(params) {
        return api.get(API, {params: params})
      },
  create: function(form) {
    return api.create(API, form)
  },
  update: function(id, form) {
    return api.update(API+'/'+id, form)
  },
  delete: function(id) {
    return api.delete(API+'/'+id)
  }
}