<template>
     <el-card v-if="route" v-loading="loading">
        <h5>{{ route.code }} | {{ dateLabel(form.start_date) }} - {{ dateLabel(form.end_date) }}</h5>
        <bar-chart :labels="viewsChart.labels" :height="350" :datasets="viewsChart.datasets" v-if="visible">
        </bar-chart>
    </el-card>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import BarChart from 'src/components/UIComponents/Charts/BarChart'

const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
};

export default {
    props: ['route', 'form', 'update'],
    components: {
        BarChart
    },
    data() {
        return {
            visible: false,
            days: [],
            loading: false,
            viewsChart: {
                labels: [],
                datasets: [
                    {
                        label: "Tickets",
                        borderColor: '#3181d7',
                        backgroundColor: '#3181d7',
                        hoverBorderColor: '#3181d7',
                        borderWidth: 5,
                        data: [],
                    }
                ]
            },
        }
    },
    watch: {
        update: function(){
            this.getData()
        }
    },
    created: function () {
        this.getData()
    },
    methods: {
        dateLabel(date){
            return this.$moment(date).format('MMM DD, YYYY')
        },
        getData() {
            let params = this.form
            params.route_code = this.route.code
            this.loading = true
            this.visible = false
            Analytic.ticketsPerDay(params)
                .then(result => {
                    this.days = result.data.days
                    this.collectData(result.data.days)
                })
                .catch(() => {

                }).finally(() => {
                    this.loading = false
                })
        },
        collectData(days) {
            this.viewsChart.labels = []
            let counts = []
            let labels = []
            days.forEach(route => {
                labels.push(route.created_day)
                counts.push(route.total_tickets)
            });
            this.viewsChart.datasets[0].data = counts
            this.viewsChart.labels = labels
            this.visible = true
        }
    }
}
</script>
<style lang="scss"></style>