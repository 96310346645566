<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="getRecords" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <user-transaction-approve-form :record="selected" v-if="selected"
            @save="getRecords"></user-transaction-approve-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-3 card">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(getRecords)">
          <div class="">
            <div class="card-body">
              <div class="form-group">
                <label>Transaction Date</label>
                <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Date is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="card-footer text-right">
              <el-button type="info" native-type="button" @click="handleRecalculate()" size="small">Recalculate</el-button>
              <el-button type="info" native-type="submit" plain size="small">Search</el-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div class="col-md-3">
      <stats-card :type="'info'" icon="fa fa-ticket" :small-title="'Total Amount'"
        :title="money(total_sum)"></stats-card>
    </div>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">User Transactions</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-2">

        </div>
        <div class="col-sm-2">

        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-6">

        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="records" style="width: 100%">

            <el-table-column :min-width="120"  class-name="td-actions" label="User">
              <template slot-scope="props">
                {{ props.row.user.name }}
                <div class="note">{{ props.row.branch.name }}</div>
              </template>
            </el-table-column>

            <el-table-column :min-width="120"  class-name="td-actions" label="Sold Tickets">
              <template slot-scope="props">
                {{ money(props.row.total_ticket_fare) }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120"  class-name="td-actions" label="Rescheduled Tickets">
              <template slot-scope="props">
                {{ money(props.row.total_rescheduled_amount) }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120"  class-name="td-actions" label="Refunded Tickets">
              <template slot-scope="props">
                {{ money(props.row.total_refunded_amount) }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120"  class-name="td-actions" label="Upgraded Tickets">
              <template slot-scope="props">
                {{ money(props.row.total_upgraded_amount) }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120"  class-name="td-actions" label="Total">
              <template slot-scope="props">
                {{ money(props.row.total) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120"  class-name="td-actions" label="Approved Amount">
              <template slot-scope="props">
                {{ money(props.row.approved_amount) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120"  class-name="td-actions" label="Action">
              <template slot-scope="props">
                <el-button type="primary" plain @click="handleshowApproveForm(props.row)" v-if="props.row.status != 2"
                  size="small">Approve now</el-button>
                <div v-if="props.row.status == 2">
                  <el-tag type="info" plain size="small">APPROVED</el-tag>
                  <div class="note">{{ props.row.updated_by ? props.row.updated_by.name : '' }}</div>
                  <div class="note">{{ props.row.updated_at }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  ClientUserTransactionRecord
} from '@/resources/client/client_user_transaction_record'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import UserTransactionApproveForm from './UserTransactionApproveForm.vue';
import StatsCard from '../../UIComponents/Cards/StatsCard.vue';

extend("required", required);

export default {
  components: {
    UserTransactionApproveForm,
    StatsCard
  },
  data() {
    return {
      showForm: false,
      records: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      start_date: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      total_sum: 0,
      term: null,
      reject_status: 1
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getRecords()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getRecords()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getRecords()
    }
  },
  created: function () {
    this.start_date = this.$moment().format('YYYY-MM-DD')
    this.getRecords()
  },
  methods: {
    handleRecalculate() {
      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      ClientUserTransactionRecord.recalculate({date: this.start_date})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Transactions have been recalculated',
          });
          this.getRecords()
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch data at the moment',
          });

        }).finally(() => {
          this.$loading = false
        })

    },
    handleClose() {
      this.showForm = false
    },
    handleshowApproveForm(record) {
      this.showForm = true
      this.selected = Object.assign({}, record)
    },
    getRecords() {
      this.handleClose()
      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        start_date: this.start_date,
        end_date: this.start_date
      }
      this.$loading = true

      ClientUserTransactionRecord.get(params)
        .then(result => {
          if (result.data.records)
            this.records = result.data.records
          this.total_sum = result.data.total
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch data at the moment',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
