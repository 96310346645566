import api from './api'

const API = '/api/client/online-bookings'

export const OnlineBooking = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  resend: function(id, form) {
    return api.update(API+'/'+id+'/re-send-email', form)
  },
  manualApprove: function(id, form) {
    return api.update(API+'/'+id+'/manual-approve', form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
