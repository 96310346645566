<template>
    <div class="">
      <el-card>
        <h5>Purchased Online Tickets (Past 30 Days)</h5>
            <bar-chart :labels="viewsChart.labels"
                         :height="350"
                         :datasets="viewsChart.datasets" v-if="visible">
              </bar-chart>
      </el-card>
            
    </div>
  </template>
  <script>
  
  import { Analytic } from '@/resources/analytics'
  import BarChart from 'src/components/UIComponents/Charts/BarChart'
  
  const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
  };
  
  export default {
    components: {
     BarChart
    },
    data() {
      return {
        visible: false,
        days: [],
        start_date: null,
        end_date: null,
        viewsChart: {
          labels: [],
          datasets: [
            {
              label: "Tickets",
              borderColor: '#3181d7',
              backgroundColor: '#3181d7',
              hoverBorderColor: '#3181d7',
              borderWidth: 5,
              data: [],
            }
          ]
        },
      }
    },
    
    created: function () {
      this.start_date = this.$moment().subtract(1, 'months');
      this.end_date = this.$moment()
      this.getOnlineTickets()
    },
    methods: {
      getOnlineTickets() {
        let params = { start_date: this.start_date.format('YYYY-MM-DD'),  end_date: this.end_date.format('YYYY-MM-DD') }
        this.$loading = true
        Analytic.onlineTicketPerDay(params)
          .then(result => {
            this.days = result.data.days
            this.collectData(result.data.days)
          })
          .catch(() => {
          
          }).finally(() => {
            this.$loading = false
          })
      },
      collectData(routes) {
        this.viewsChart.labels = []
        let counts = []
        let labels = []
        routes.forEach(route => {
          labels.push(route.created_day)
          counts.push(route.total_tickets)
        });
        this.viewsChart.datasets[0].data = counts
        this.viewsChart.labels= labels
        this.visible = true
      }
    }
  }
  </script>
  <style lang="scss"></style>
  