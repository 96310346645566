<template>
<div class="row">
  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <price-form @save="getPrices()" v-if="showForm" :price="selected"></price-form>
      </div>
    </div>

  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-header">
      <div class="category">Manage Prices</div>
    </div>
    <div class="card-body row">
      <div class="col-sm-8">
         <el-select class="select-default" v-model="filter.route_id" placeholder="Search by Route" size="small">
                      <el-option :value="route.id" :label="route.code" v-for="route in routes" :key="route.id">{{ route.code }}</el-option>
                    </el-select>
                       <el-select class="select-default" v-model="filter.accommodation_id" placeholder="Search by Accommodation" size="small">
                      <el-option :value="accommodation.id" :label="accommodation.name" v-for="accommodation in accommodations" :key="accommodation.id">{{ accommodation.name }}</el-option>
                    </el-select>
        <el-select class="select-default" v-model="filter.passenger_type" placeholder="Select Passenger" filterable size="small">
          <el-option :value="i" :label="p" v-for="p, i in $store.state.passenger_types" :key="i">{{ p }}</el-option>
        </el-select>

        <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        <el-button type="info" size="small" class="ml-2" @click="createMissing">Create Missing Prices</el-button>

      </div>
      <div class="col-sm-4">
        <div class="pull-right">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-sm-12 mt-2">
        <el-table class="table-striped" header-row-class-name="text-primary" :data="prices" style="width: 100%">
          <el-table-column :min-width="120" class-name="td-actions" label="Route">
            <template slot-scope="props">
              {{ props.row.route.code }}
            </template>
          </el-table-column>
          <el-table-column :min-width="120" class-name="td-actions" label="Accommodation">
            <template slot-scope="props">
              {{ props.row.accommodation.name }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" class-name="td-actions" label="Passnger Type">
            <template slot-scope="props">
              {{ $store.state.passenger_types[props.row.passenger_type] }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" class-name="td-actions" label="Amount">
            <template slot-scope="props">
              {{ money(props.row.amount) }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" class-name="td-actions" label="Actions">
            <template slot-scope="props">

              <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
              <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  Price
} from '@/resources/price'
import {
  Vessel
} from '@/resources/vessel'
import {
  Route
} from '@/resources/route'
import {
  Accommodation
} from '@/resources/accommodation'

import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import PriceForm from './PriceForm.vue'

export default {
  components: {
    ConfirmDelete,
    PriceForm
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      prices: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      term: null,
      vessels: [],
      routes: [],
      accommodations: [],
      filter: {
        vessel_detail_id: null,
        route_id: null,
        accommodation_id: null,
        passenger_type: null,
      }
    }
  },
  watch: {
    filter: {
      handler: function (newValue) {
        this.getPrices()
      },
      deep: true
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPrices()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPrices()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPrices()
    }
  },
  created: function () {
    this.getPrices()
    this.getRoutes()
    this.getVessels()
    this.getACcommodations()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    createMissing() {
      this.$loading = true
      this.$loadingText = "Generating. Please wait"

      Price.createMissing({})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Prices has been created',
          });
          this.getPrices()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      Price.delete({
          id: model.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Price has been removed',
          });
          this.getPrices()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPrices() {
      this.handleClose()
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        passenger_type: this.filter.passenger_type,
        vessel_detail_id: this.filter.vessel_detail_id,
        route_id: this.filter.route_id,
        accommodation_id: this.filter.accommodation_id
      }
      this.$loading = true
      Price.get({
          params: params
        })
        .then(result => {
          if (result.data.prices)
            this.prices = result.data.prices
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getRoutes() {
      this.$loading = true
      Route.get({})
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getVessels() {
      this.$loading = true
      Vessel.get({})
        .then(result => {
          if (result.data.vessels)
            this.vessels = result.data.vessels
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getACcommodations() {
      this.$loading = true
      Accommodation.get({})
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
