<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <invoice-form @save="getInvoices()" v-if="showForm" :invoice="selected"></invoice-form>
        </div>
      </div>
    </el-drawer>

    <el-dialog title="" :visible.sync="showGenerate" direction="btt" :before-close="handleClose"
      :destroy-on-close="true">
      <div>
        <invoice-generate :invoice="selected" @save="getInvoices()"></invoice-generate>
      </div>
    </el-dialog>

    <div class="col-md-3">
      <stats-card :type="'primary'" icon="fa fa-files-o" :small-title="'GENERATE INVOICE FOR'" :title="invoice_date">
        <div class="stats link" slot="footer" @click="handleGenerate">
          <i :class="'fa fa-external-link'"></i>
          GO
        </div>
      </stats-card>
    </div>

    <div class="col-md-3">
      <stats-card :type="'success'" icon="fa fa-money" :small-title="'PAID INVOICES'" :title="paid_invoices">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-external-link'"></i>
        </div>
      </stats-card>
    </div>


    <div class="col-md-3">
      <stats-card :type="'warning'" icon="fa fa-exclamation-circle" :small-title="'UNPAID INVOICES'"
        :title="unpaid_invoices">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-external-link'"></i>
        </div>
      </stats-card>
    </div>


    <div class="col-md-3">
      <stats-card :type="'danger'" icon="fa fa-window-close" :small-title="'OVERDUE INVOICES'"
        :title="overdue_invoices">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-external-link'"></i>
        </div>
      </stats-card>
    </div>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Invoices</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <!-- <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button> -->
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="invoices" style="width: 100%">

            <el-table-column :min-width="120" class-name="td-actions" label="Client">
              <template slot-scope="props">
                {{ props.row.client.name }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Invoice No.">
              <template slot-scope="props">
                {{ props.row.invoice_number }}
              </template>
            </el-table-column>
            <el-table-column :min-width="180" class-name="td-actions" label="Title">
              <template slot-scope="props">
                {{ props.row.title }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Amount">
              <template slot-scope="props">
                {{ money(props.row.total_amount) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Due Date">
              <template slot-scope="props">
                {{ props.row.due_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="100" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <el-tag v-if="props.row.is_final" type="primary">FINAL</el-tag>
                <el-tag v-if="!props.row.is_final" type="info">DRAFT</el-tag>
                <el-tag type="info"> {{ $store.state.invoice_status[props.row.status] }}
                </el-tag>

              </template>
            </el-table-column>

            <el-table-column :min-width="160" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <div>
                  <el-popconfirm confirm-button-text='Yes' cancel-button-text='Cancel' icon="el-icon-info"
                    icon-color="red" :title="'Send to client?'" @confirm="handleFinal(props.row)" v-if="!props.row.is_final">
                    <el-button size="mini" type="warning" slot="reference" style="margin-right: 5px;"> <i
                        class="fa fa-check"></i></el-button>
                  </el-popconfirm>

                  <el-tooltip class="item" effect="dark" content="View" placement="top-start" v-if="props.row.status == 1">

                    <el-button type="default" link @click="handleEdit(props.row, true)" size="mini" style="margin-right: 5px;">
                      <i class="fa fa-eye"></i>
                    </el-button>
                    </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Edit" placement="top-start" v-if="props.row.status != 1">

                    <el-button type="default" link @click="handleEdit(props.row)" size="mini" style="margin-right: 5px;">
                      <i class="fa fa-edit"></i>
                    </el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Download" placement="top-start">
                    <el-button type="default" link @click="handleDownload(props.row)" size="mini" style="margin-right: 5px;">
                      <i class="fa fa-download"></i>
                    </el-button>
                  </el-tooltip>

                  <el-popconfirm confirm-button-text='Yes' cancel-button-text='No, Thanks' icon="el-icon-info"
                    icon-color="red" :title="'Delete?'" @confirm="handleDelete(props.row)" v-if="!props.row.is_final">
                    <el-button size="mini" type="danger" slot="reference" style="margin-right: 5px;"> <i
                        class="fa fa-trash"></i></el-button>
                  </el-popconfirm>

                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Invoice
} from '@/resources/invoice'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import InvoiceForm from './InvoiceForm.vue'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'
import InvoiceGenerate from './InvoiceGenerate.vue'

export default {
  components: {
    ConfirmDelete,
    InvoiceForm,
    StatsCard,
    InvoiceGenerate
  },
  data() {
    return {
      showGenerate: false,
      showDelete: false,
      showForm: false,
      invoices: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      invoice_date: null,
      paid_invoices: 0,
      unpaid_invoices: 0,
      overdue_invoices: 0
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getInvoices()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getInvoices()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getInvoices()
    }
  },
  created: function () {
    let date = this.$moment();
    this.invoice_date = date.format('MMM YYYY')

    this.getInvoices()
  },
  methods: {
    handleFinal(invoice) {
      this.$loading = true
      this.$loadingText = "Removing"

      Invoice.final({
        id: invoice.id
      })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Invoice has been updated',
          });
          this.getInvoices()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleEdit(invoice, view) {
      this.showForm = true
      this.selected = Object.assign({}, invoice)
      this.selected.view = view
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showGenerate = false
    },
    handleForm() {
      this.showForm = true
    },
    handleGenerate() {
      let date = this.$moment();

      this.selected = {
        start_date: date.startOf('month').format('YYYY-MM-DD'),
        end_date: date.endOf('month').format('YYYY-MM-DD')
      }
      this.showGenerate = true
    },
    handleDownload(invoice) {
      this.$loading = true
      this.$loadingText = "Removing"

      Invoice.downloadPdf(invoice.id)
        .then(result => {
          var printWindow = window.open(result.data.pdf.link, '_blank');
          printWindow.print();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDelete(payroll) {
      this.$loading = true
      this.$loadingText = "Removing"

      Invoice.delete({
          id: payroll.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Invoice has been removed',
          });
          this.getInvoices()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getInvoicesStatus(list) {
      let self = this
      self.unpaid_invoices = 0
      self.paid_invoices = 0
      self.overdue_invoices = 0
      list.forEach(item => {
        if (item.status == 0) self.unpaid_invoices = item.total_amount
        if (item.status == 1) self.paid_invoices = item.total_amount
        if (item.status == 2) self.overdue_invoices = item.total_amount
      });
    },
    getInvoices() {
      this.handleClose()
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      Invoice.get({
          params: params
        })
        .then(result => {
          if (result.data.invoices)
            this.invoices = result.data.invoices
          this.pagination = result.data.meta
          this.getInvoicesStatus(result.data.list)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
