<template>
    <div style="padding: 20px">
      <div class="row" v-if="booking">
        <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="getReservations" size="90%"
      :destroy-on-close="true" :append-to-body="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <reservation-form @save="getReservations()" v-if="showForm" :reservation="selected"></reservation-form>
        </div>
      </div>

    </el-drawer>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-12">
              <div class="table-container">
                <h5>RESERVATIONS</h5>
                <el-button size="small" @click="getReservations"><i class="fa fa-refresh"></i> REFRESH</el-button>
                <el-button size="small" @click="handleShowForm"><i class="fa fa-plus"></i> CREATE</el-button>

                <el-table class="table-striped" header-row-class-name="text-primary" :data="reservations" style="width: 100%">
                  <el-table-column :min-width="250" fclass-name="td-actions" label="Description">
                    <template slot-scope="props">
                      {{ props.row.description }}
                    </template>
                  </el-table-column>
                  <el-table-column :min-width="150" fclass-name="td-actions" label="Accommodation">
                    <template slot-scope="props">
                      {{ props.row.accommodation ? props.row.accommodation.name : '' }}
                    </template>
                  </el-table-column>
                  
                  <el-table-column :min-width="100" fclass-name="td-actions" label="Total">
                    <template slot-scope="props">
                      {{ props.row.total }}
                    </template>
                  </el-table-column>

                  <el-table-column :min-width="150" fclass-name="td-actions" label="Reserved By">
                    <template slot-scope="props">
                      {{ props.row.user ? props.row.user.name : '' }}
                    </template>
                  </el-table-column>
  
                  <el-table-column :min-width="150" fclass-name="td-actions" label="Action">
                    <template slot-scope="props">
                      <el-button @click="handleEdit(props.row)">EDIT</el-button>
                      <confirm-button title="You're about to delete this reservation. Proceed?"
                          label="DELETE" :size="''"
                    :type="''" class="mt-3" @onClick="handleDelete(props.row)">
                  </confirm-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import {
    Reservation
  } from '@/resources/reservation'
import ReservationForm from './ReservationForm.vue'
import ConfirmButton from '../../../Common/ConfirmButton.vue'
  

  export default {
    props: ['booking'],
    components:{ ReservationForm, ConfirmButton},
    data() {
      return {     
        showForm: false,
        reservations: [],
        selected: null,
      }
    },
    watch: {
      booking() {
        this.getReservations()
      },
    },
    created: function () {
      this.getReservations()
    },
    methods: {
      handleEdit(reservation){
        this.showForm = true 
        this.selected = Object.assign({}, reservation)
      },
      handleShowForm(){
        this.showForm = true 
        this.selected = { booking_id: this.booking.id }
      },
      handleDelete(reservation) {
        Reservation.delete(reservation.id)
          .then(result => {
            this.$notify({
              type: 'success',
              title: "Success",
              text: 'Reservation removed',
            });
            this.getReservations()
          })
          .catch(() => {
  
          }).finally(() => {
            this.$loading = false
          })
      },
      getReservations() {
        let params = {
            booking_id: this.booking.id
        }
        this.$loading = true
        this.showForm = false
      
        Reservation.get(params)
          .then(result => {
            this.reservations = result.data.reservations
          })
          .catch(() => {
  
          }).finally(() => {
            this.$loading = false
          })
      },
    }
  }
  </script>