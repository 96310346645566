<template>
    <div class="row" v-if="isAllowed('ANALYTICS', 'VIEW')">
        <div class="col-md-12">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(submit)">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Boarded Passengers Per Day Analytics</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                <label>Start Date</label>
                                <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                                    <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day"
                                        class="form-group" :class="[
                                            { 'has-danger': failed },
                                            { 'has-success': passed }]">
                                    </el-date-picker>
                                    <br><span class="error-text" v-if="failed">Start date is required</span>
                                </ValidationProvider>
                            </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                <label>End Date</label>
                                <ValidationProvider name="end_date" v-slot="{ passed, failed }">
                                    <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day"
                                        class="form-group" :class="[
                                            { 'has-danger': failed },
                                            { 'has-success': passed }]">
                                    </el-date-picker>
                                    <br><span class="error-text" v-if="failed">End date is required</span>
                                </ValidationProvider>
                            </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Search</label><br>
                                    <el-button type="info" native-type="submit"><i class="fa fa-search"></i></el-button>
                                </div>
                            </div>
                            
                           
                        </div>
                      
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <div class="col-md-12" v-for="route in routes" :key="route.id">
            <tickets-by-route-per-day :route="route" :form="form" :update="update"></tickets-by-route-per-day>
            <br>
        </div>
    </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import BarChart from 'src/components/UIComponents/Charts/BarChart'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Route
} from '@/resources/route'
import TicketsByRoutePerDay from './TicketsByRoutePerDay.vue';

extend("required", required);

const tooltipOptions = {
    tooltipFillColor: "rgba(0,0,0,0.5)",
    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipFontSize: 14,
    tooltipFontStyle: "normal",
    tooltipFontColor: "#fff",
    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    tooltipTitleFontSize: 14,
    tooltipTitleFontStyle: "bold",
    tooltipTitleFontColor: "#fff",
    tooltipYPadding: 6,
    tooltipXPadding: 6,
    tooltipCaretSize: 8,
    tooltipCornerRadius: 6,
    tooltipXOffset: 10,
};

export default {
    components: {
        BarChart,
        TicketsByRoutePerDay
    },
    data() {
        return {
            visible: false,
            days: [],
            routes: [],
            form: {
                start_date: null,
                end_date: null,
            },
            update: 0
        }
    },
    created: function () {
        this.form.start_date = this.$moment().subtract(1, 'months');
        this.form.end_date = this.$moment()
        this.getRoutes()
    },
    methods: {
        getRoutes() {
            this.$loading = true
            Route.get({
                per_page: 999
                })
                .then(result => {
                    this.routes = result.data.routes
                })
                .catch(() => {

                }).finally(() => {
                this.$loading = false
                })
        },
        submit(){
            this.update += 1
        }
    }
}
</script>
<style lang="scss"></style>