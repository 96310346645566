<template>
  <div class="row">
    <div class="col-md-3 card">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(getMessages)">
          <div class="">
            <div class="card-body">
              <div class="form-group">
                <label>Start Date</label>
                <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Start date is required</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>End Date</label>
                <ValidationProvider name="end_date" v-slot="{ passed, failed }">
                  <el-date-picker v-model="end_date" type="date" placeholder="Pick a day" class="form-group" :class="[
        { 'has-danger': failed },
        { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">End date is required</span>
                </ValidationProvider>
              </div>

            </div>
            <div class="card-footer text-right">
              <el-button type="info" native-type="submit">Search</el-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div class="col-md-3">
      <stats-card :type="'primary'" icon="fa fa-envelope" small-title="TOTAL SENT SMS" :title="total_sent">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-envelop'"></i>
        </div>
      </stats-card>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Messages</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="messages" style="width: 100%">


            <el-table-column :min-width="120" class-name="td-actions" label="Contact Numbers">
              <template slot-scope="props">
                {{ props.row.contact_numbers }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Message">
              <template slot-scope="props">
                {{ props.row.message }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Response">
              <template slot-scope="props">
                {{ props.row.response }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Total Credits">
              <template slot-scope="props">
                {{ props.row.total_credits }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Date Created">
              <template slot-scope="props">
                {{ props.row.created_on }}
              </template>
            </el-table-column>





            <el-table-column :min-width="120" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <span v-if="props.row.status == 0"> NEW</span>
                <span v-if="props.row.status == 1"> SENDING</span>
                <span v-if="props.row.status == 2"> SENT</span>
                <span v-if="props.row.status == 3"> ERROR</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Message } from '@/resources/message'
import StatsCard from '../../../UIComponents/Cards/StatsCard.vue'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

extend("required", required);


export default {
  components: { StatsCard },
  data() {
    return {
      total_sent: 0,
      messages: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      term: null,
      start_date: null,
      end_date: null
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getMessages()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getMessages()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getMessages()
    }
  },
  created: function () {
    this.start_date = this.$moment().format('YYYY-MM-DD')
    this.end_date = this.$moment().format('YYYY-MM-DD')
    this.getMessages()
  },
  methods: {
    getMessages() {
      this.$loading = true

      let params = {
        start_date: this.$moment(this.start_date).format('YYYY-MM-DD'),
        end_date: this.$moment(this.end_date).format('YYYY-MM-DD'),
        term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage
      }

      Message.get({ params: params })
        .then(result => {
          if (result.data.messages)
            this.messages = result.data.messages
          this.pagination = result.data.meta
          this.total_sent = result.data.total_sent
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch messages',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
