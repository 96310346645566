<template>
    <div class="row">
      <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
        :destroy-on-close="true">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <online-booking-manualapprove-form @save="getBookings()" v-if="showForm" :online_booking="selected"></online-booking-manualapprove-form>
          </div>
        </div>
  
      </el-drawer>
  
      <div class="col-md-12 card">
        <div class="card-header">
          <div class="category">Manage Bookings</div>
        </div>
        <div class="card-body row">
          <div class="col-sm-6">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped" header-row-class-name="text-primary" :data="bookings" style="width: 100%">
  
              <el-table-column :min-width="150"  class-name="td-actions" label="CODE">
                <template slot-scope="props">
                  REF NO: {{ props.row.code }} <br>
                  TXN CODE: {{ props.row.transaction_code }}
                </template>
              </el-table-column>
  
              <el-table-column :min-width="170"  class-name="td-actions" label="Contact Person">
                <template slot-scope="props">
                  {{ props.row.passenger_contact.complete_name }}<br>
                  {{ props.row.passenger_contact.contact_number }} <br>
                  {{ props.row.passenger_contact.email }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120"  class-name="td-actions" label="BOOKING">
                <template slot-scope="props">
                  {{ props.row.booking.booking_date }} {{  props.row.booking.formatted_time }}<br>
                  {{  props.row.booking.route.code }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120"  class-name="td-actions" label="Expiration">
                <template slot-scope="props">
                  {{ props.row.expiry }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120"  class-name="td-actions" label="Total Amount">
                <template slot-scope="props">
                  {{ money(props.row.total_amount) }}<br>
                </template>
              </el-table-column>
              <el-table-column :min-width="120"  class-name="td-actions" label="Status">
                <template slot-scope="props">
                  <el-tag v-if="props.row.status == 1" type="success">COMPLETED</el-tag>
                  <el-tag v-if="props.row.status == 0">PENDING</el-tag>
                  <el-tag v-if="props.row.status == 2">EXPIRED</el-tag>

                </template>
              </el-table-column>
  
              <el-table-column :min-width="120"  class-name="td-actions" label="Actions">
                <template slot-scope="props">
  
                  <el-button v-if="props.row.status != 1" type="default" link @click="handleEdit(props.row)">
                    <i class="fa fa-edit"></i> APPROVE
                  </el-button>

                  <confirm-button v-if="props.row.status == 1" title="Re-send confirmation email?"
                        label="RESEND MAIL" :size="'medium'"
                  :type="'primary'" class="mt-3" @onClick="handleResend(props.row)">
                </confirm-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  
  import { OnlineBooking } from '@/resources/online_booking'
  import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import OnlineBookingManualapproveForm from './OnlineBookingManualapproveForm.vue'
import ConfirmButton from '../../Common/ConfirmButton.vue'
  
  export default {
    components: { ConfirmDelete, OnlineBookingManualapproveForm, ConfirmButton },
    data() {
      return {
        showDelete: false,
        showForm: false,
        bookings: [],
        selected: null,
        perPageOptions: [5, 10, 15],
        pagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        term: null,
      }
    },
    watch: {
      term: function () {
        if (!this.term || this.term.length > 2) {
          this.getBookings()
        }
      },
      'pagination.perPage': function () {
        if (this.pagination.currentPage == 1) {
          this.getBookings()
        } else {
          this.pagination.currentPage = 1
        }
      },
      'pagination.currentPage': function () {
        this.getBookings()
      }
    },
    created: function () {
      this.getBookings()
    },
    methods: {
      handleResend(model){
        this.$loading = true
        
          OnlineBooking.resend(model.id) 
            .then(result => {
              this.$notify({
                type: 'success',
                title: "Success",
                text: 'Email sent',
              });
            })
            .catch(error => {
    
            }).finally(() => {
              this.$loading = false
            })
      },
      handleEdit(model) {
        this.showForm = true
        this.selected = Object.assign({}, model)
      },
      handleClose() {
        this.showDelete = false
        this.showForm = false
      },
      handleForm() {
        this.showForm = true
        this.selected = null
      },
      getBookings() {
        this.handleClose()
        let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
        this.$loading = true
        OnlineBooking.get({ params: params })
          .then(result => {
            if (result.data.online_bookings)
              this.bookings = result.data.online_bookings
            this.pagination = result.data.meta
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              title: "Error",
              text: 'Unable to fetch bookings',
            });
  
          }).finally(() => {
            this.$loading = false
          })
      }
    }
  }
  </script>
  <style lang="scss"></style>
  