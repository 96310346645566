<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">View All Tickets</div>
      </div>
      <div class="card-body row">

        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>

        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">
            <el-table-column :min-width="120" class-name="td-actions" label="Group Code">
              <template slot-scope="props">
                {{ props.row.group_code }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Ticket Code">
              <template slot-scope="props">
                {{ props.row.ticket_detail.code }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" class-name="td-actions" label="Passenger Name">
              <template slot-scope="props">
                {{ props.row.ticket_detail.passenger.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="60" class-name="td-actions" label="Ticket">
              <template slot-scope="props">
                {{ props.row.type == 1 ? 'OW' : 'RT' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" class-name="td-actions" label=" Type">
              <template slot-scope="props">
                {{ $store.state.passenger_types[props.row.passenger_type] }}
              </template>
            </el-table-column>
            <el-table-column :min-width="80" class-name="td-actions" label="Route">
              <template slot-scope="props">
                {{ props.row.booking.route.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" class-name="td-actions" label="ACMDXN">
              <template slot-scope="props">
                {{ props.row.accommodation.code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" class-name="td-actions" label="Date/Time">
              <template slot-scope="props">
                {{ props.row.booking.booking_date }}<br> {{ props.row.booking.formatted_time }} <br>
                {{  props.row.booking.vessel.code }}
              </template>
            </el-table-column>
            <el-table-column :min-width="80" class-name="td-actions" label="Total Fare">
              <template slot-scope="props">
                {{ money(props.row.ticket_detail.total_fare) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" class-name="td-actions" label="Seat No">
              <template slot-scope="props">
                {{ props.row.ticket_seat ? props.row.ticket_seat.seat_number : 'N/A' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" class-name="td-actions" label="STATUS">
              <template slot-scope="props">
                <div v-if="props.row.is_refunded">REFUNDED</div>
                <div v-else>
                  {{ $store.state.tickets_status[props.row.status] }}
                </div>
                <div v-if="props.row.has_been_rescheduled">
                  (RESCHEDULED)
                </div>

                <div v-if="props.row.reject_status == 1">
                  (REJECT REQUESTED)
                </div>
                <div v-if="props.row.reject_status == 2">
                  (REJECTED)
                </div>

              </template>
            </el-table-column>

          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ClientTicket
} from '@/resources/client/client_ticket'

export default {
  components: {
  },
  data() {
    return {
      tickets: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    this.getTickets()
  },
  methods: {
    getTickets() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      ClientTicket.get(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
