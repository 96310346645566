<template>
    <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              {{ form.id ? 'Update reservation' : 'Create new reservation' }}
            </h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>Description</label>
              <ValidationProvider name="description" rules="required" v-slot="{ passed, failed }">
                <fg-input type="textarea" :error="failed ? 'The description field is required' : null" :hasSuccess="passed" name="description"
                  v-model="form.description">
                </fg-input>
              </ValidationProvider>
            </div>
  
            
            <div class="form-group">
            <label>Accommodation</label>
            <el-select v-model="form.accommodation_id" placeholder="Select">
              <el-option v-for="accom in accommodations" :key="accom.id" :label="accom.name" :value="accom.id">
              </el-option>
            </el-select>
          </div>

          <div class="form-group">
            <label>Total</label>
            <el-input type="number" v-model="form.total"></el-input>
          </div>

          </div>
          <div class="card-footer text-right">
            <p-button type="info" native-type="submit">Save</p-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </template>
  <script>
  
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import { Reservation } from '@/resources/reservation'
  import { Accommodation } from '@/resources/accommodation'

  extend("email", email);
  extend("required", required);
  
  export default {
    props: ['reservation'],
    data() {
      return {
        form: {
          code: "",
          name: "",
        },
        loading: false,
        accommodations: []
      };
    },
    created: function () {
        this.getAccommodations()
      this.form = Object.assign({}, this.reservation)
    },
    methods: {
      submit() {
        this.loading = true
  
        let request = this.form.id ? Reservation.update(this.form.id, this.form) : Reservation.create(this.form)
  
        request
          .then(result => {
            this.$notify({
              type: 'success',
              title: "Success",
              text: 'Reservation has been saved',
            });
            this.$emit('save', true)
          })
          .catch(error => {
            let message = error.response.data.message || 'Unable to save this time'
            this.$notify({
              type: 'danger',
              title: "Error",
              text: message,
            });
          }).finally(() => {
            this.loading = false
          })
      },
      getAccommodations() {
        this.$loading = true
        Accommodation.get({})
            .then(result => {
            if (result.data.accommodations)
                this.accommodations = result.data.accommodations
            })
            .catch(() => {

            }).finally(() => {
            this.$loading = false
            })
        },
    }
  }
  </script>
  <style></style>
  